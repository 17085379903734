import React, { Component } from 'react';
import $ from 'jquery';
import {NavLink} from 'react-router-dom';
import {api_url} from '../../Config.js';
import DComponent from './DynamicComponent.js';
import styled from 'styled-components';
import * as ReactBootStrap from 'react-bootstrap';
const Container = styled.div`
  background-color:#444;
  color:white;
  padding:16px;
  position:fixed;
  top:${props=> props.top}px;
  right:16px;
  z-index:999;
  transition: top 0.9s ease;
`;
class MostView extends Component {
 constructor(props) {
    super(props);
    this.state = {
      error: null,
      products: [],
      backupproducts: [],
      cartProducts:[],
      totalProducts:0,
      brands:[],
      itemShow:10,
      top:-8000,
      categories:[],
      productsFilters:[],
       filter:[],
      totalItems:"",
      loading:false,
      addtocart:'',
      itemquantity:1,
      items: [],
      cartCount:localStorage.getItem('cartCount'),
      filterId:localStorage.getItem('filterId'),
      base_url:api_url.base_url,
      img_url:api_url.img_url,
      filterData:[],
      fillterCategory:'',
      filterOption:'',
      com_url:api_url.com_url,
      sale_view:1,
      sale_enable:1,
      settings:[],
      showItems:15,
    }
    this.loadmore = this.loadmore.bind(this);
    this.viewAllProducts = this.viewAllProducts.bind(this);
    this.setStateOfParent = this.setStateOfParent.bind(this);
  }
async setStateOfParent (result) {

      this.props.setStateOfParent(result);
      await this.setState({
        cartCount:result
      },()=>{
        localStorage.setItem('cartCount',result);
        localStorage.setItem('cartCount',result);

      })
    }
  componentDidMount() {

    const categoryUrl =this.state.com_url+'moiz_api/get_admin_settings';
    fetch(categoryUrl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
           settings: result

          },function(){
            this.setState({
              sale_view:this.state.settings[0].most_viewed_view,
              sale_enable:this.state.settings[0].most_viewed_enable,
            })
          });
        },
        (error) => {
          this.setState({ error });
        }
      )



    var filterId = this.state.filterId;

    const apiUrl = this.state.base_url+'products_api/getProducts/15/all/all/mostviewed/1';

    this.setState({products:[],backupproducts:[],totalProducts:0});
     var api_Data = {user_id:sessionStorage.getItem('userData')};
       fetch(apiUrl,{
          method: 'POST',
          headers:
          {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body:JSON.stringify(api_Data)
        })
      .then(res => res.json())
      .then(
        (result) => {
          console.log('most viewed products are '+result)
          this.setState({
            products: result.products,
            backupproducts:result.products,
            loading:false,
            totalProducts:result.products.length,
          });
        },
        (error) => {
          this.setState({ error });
        }
      )
  }

  addToCart(e){
  this.setState((old)=>{
    return {cartCount:old.cartCount + 1}
   } )
// localStorage.clear();
this.setState({
      top:5,
    },function(){
      setTimeout(()=>{
        this.setState({
          top: -800,
        })
      },1500)
    })

this.setState({addtocart:"Product Added to Cart!"})
var count = this.state.cartCount;
count = parseInt(this.state.cartCount)+parseInt(1);
this.setState({cartCount:count},function(){})

localStorage.setItem('cartCount',count);
var newproducts = JSON.parse(localStorage.getItem('session'))
var products =  this.state.products[e.target.name] ;
var proid=products.id



 products['itemQty']='1';
        a = JSON.parse(localStorage.getItem('session')) || [];
        a.push(products);
        localStorage.setItem('session', JSON.stringify(a));






for(var i= 0 ; i<newproducts.length ; i++)
{
var newid = newproducts.map((key,index)=>(key.id))
var idd = newproducts[i]
  if(products.id == idd.id)
  {

const updateditems = newproducts.filter(item=>item.id !==idd.id);

localStorage.setItem('session', JSON.stringify(updateditems));


    var changeproduct = newproducts[i]

    var qty = changeproduct.itemQty

   products['itemQty']=parseInt(changeproduct.itemQty)+ parseInt(1);
   var a = [];
    a = JSON.parse(localStorage.getItem('session')) || [];
    var length = a.length;
    a.push(products);
    localStorage.setItem('session', JSON.stringify(a));

  }
}

}

  async viewAllProducts()
  {
    const apiUrl = this.state.base_url+'products_api/getProducts/all';
   this.setState({products:[],backupproducts:[],totalProducts:0});
    var api_Data = {user_id:sessionStorage.getItem('userData')};
    await fetch(apiUrl,{
          method: 'POST',
          headers:
          {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body:JSON.stringify(api_Data)
        })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            products: result.products,
            backupproducts:result.products,
            loading:false,
            totalProducts:result.total,
          });
        },
        (error) => {
          this.setState({ error });
        }
      )
  }

loadMoreItems()
{
  this.setState((prev) => {
      return {showItems: prev.showItems + 15};
    });
}
 async loadmore(){
      var length = parseInt(this.state.products.length) + 20;

      const apiUrl = this.state.base_url+'products_api/getProducts/'+length+'/all/all/mostviewed/1';
       this.state.products=[];
       this.state.backupproducts=[];
        var api_Data = {user_id:sessionStorage.getItem('userData')};
        await fetch(apiUrl,{
              method: 'POST',
              headers:
              {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
              },
              body:JSON.stringify(api_Data)
            })
          .then(res => res.json())
          .then(
            (result) => {
              this.setState({
                products: result.products,
                backupproducts:result.products,
                loading:false,
                totalProducts:result.total,
              });
            },
            (error) => {
              this.setState({ error });
            }
          )

      // this.setState((old)=>{
      //   return {itemShow:old.itemShow + 10}
      // })
  }

  componentDidUpdate(){


     var itemsMainDiv = ('.MultiCarousel');
    var itemsDiv = ('.MultiCarousel-inner');
    var itemWidth = "";

    $('.leftLst, .rightLst').click(function () {
        var condition = $(this).hasClass("leftLst");
        if (condition)
            click(0, this);
        else
            click(1, this)
    });

    ResCarouselSize();




    $(window).resize(function () {
        ResCarouselSize();
    });

    //this function define the size of the items
    function ResCarouselSize() {
        var incno = 0;
        var dataItems = ("data-items");
        var itemClass = ('.item');
        var id = 0;
        var btnParentSb = '';
        var itemsSplit = '';
        var sampwidth = $(itemsMainDiv).width();
        var bodyWidth = $('body').width();
        $(itemsDiv).each(function () {
            id = id + 1;
            var itemNumbers = $(this).find(itemClass).length;
            btnParentSb = $(this).parent().attr(dataItems);
            itemsSplit = btnParentSb.split(',');
            $(this).parent().attr("id", "MultiCarousel" + id);


            if (bodyWidth >= 1200) {
                incno = itemsSplit[3];
                itemWidth = sampwidth / incno;
            }
            else if (bodyWidth >= 992) {
                incno = itemsSplit[2];
                itemWidth = sampwidth / incno;
            }
            else if (bodyWidth >= 768) {
                incno = itemsSplit[1];
                itemWidth = sampwidth / incno;
            }
            else {
                incno = itemsSplit[0];
                itemWidth = sampwidth / incno;
            }
            $(this).css({ 'transform': 'translateX(0px)', 'width': itemWidth * itemNumbers });
            $(this).find(itemClass).each(function () {
                $(this).outerWidth(itemWidth);
            });

            $(".leftLst").addClass("over");
            $(".rightLst").removeClass("over");

        });
    }


    //this function used to move the items
    function ResCarousel(e, el, s) {
        var leftBtn = ('.leftLst');
        var rightBtn = ('.rightLst');
        var translateXval = '';
        var divStyle = $(el + ' ' + itemsDiv).css('transform');
        var values = divStyle.match(/-?[\d\.]+/g);
        var xds = Math.abs(values[4]);
        if (e == 0) {
            translateXval = parseInt(xds) - parseInt(itemWidth * s);
            $(el + ' ' + rightBtn).removeClass("over");

            if (translateXval <= itemWidth / 2) {
                translateXval = 0;
                $(el + ' ' + leftBtn).addClass("over");
            }
        }
        else if (e == 1) {
            var itemsCondition = $(el).find(itemsDiv).width() - $(el).width();
            translateXval = parseInt(xds) + parseInt(itemWidth * s);
            $(el + ' ' + leftBtn).removeClass("over");

            if (translateXval >= itemsCondition - itemWidth / 2) {
                translateXval = itemsCondition;
                $(el + ' ' + rightBtn).addClass("over");
            }
        }
        $(el + ' ' + itemsDiv).css('transform', 'translateX(' + -translateXval + 'px)');
    }

    //It is used to get some elements from btn
    function click(ell, ee) {
        var Parent = "#" + $(ee).parent().attr("id");
        var slide = $(Parent).attr("dataSlide");
        ResCarousel(ell, Parent, slide);
    }

}



  render() {
    const {error, products,loading,loadbtn} = this.state;
    if(this.state.sale_enable == 0)
    {
      return null;
    }
    if(this.state.products.length == 0)
    {
      return null;
    }
    return(
      <div>
        <Container top={this.state.top}>{this.state.addtocart}</Container>
        <section className="tabs_blog new_add_products">
          <div className="container">
            <div className="row">
                  <div className="col-sm-12 new_arrivals">
                    <section className="best_deal">

                        <div className="section-title">
                          <div className="bar"></div>
                        </div>
                        <div className="shadow_box">
                          <div className="row">
                          <div className="withoutSlider">
                            {(
                            (this.state.sale_view == 1) ?
                                products.slice(0, this.state.showItems).map((product,index) => {
                                          if(product.is_homepage==='1')
                                          {
                                          return(
                                                  <DComponent setStateOfParent={this.setStateOfParent} title={product} key={index} user_id={sessionStorage.getItem('userData')} value={index} onClick={this.addToCart} />
                                            )

                                          }

                                        })
                              :""
                           )}
                            </div>
                          </div>
                          <div className="read_more_btn">
                              {this.state.totalProducts > this.state.products.length && this.state.products.length > 0 &&

                                <button
                                className="loadmore_products"
                                 style={{ fontSize: "18px"}}
                                 type='button'
                                 onClick={this.loadmore}
                                 >
                                 {loadbtn && (
                                   <i
                                     className="fa fa-refresh fa-spin"
                                     style={{ marginRight: "5px" }}
                                   />
                                 )}
                                 {loadbtn && <span>Loading...</span>}
                                 {!loadbtn && <span>Load More</span>}
                               </button>
                              }
                                <NavLink to='/search-results/all/all/featured-products'    className="show_all_button">Show All</NavLink>
                            </div>
                          {(
                            (this.state.sale_view === 2) &&
                            <div className="shadow_box">
                              <div className="row">
                                  <div className="MultiCarousel" data-items="1,2,3,4" dataslide="1" id="MultiCarousel"  data-interval="1000">
                                      <div className="MultiCarousel-inner">
                                      {products.map((product,index) =>{
                                          if(product.is_homepage==='1')
                                          {
                                          return(
                                                  <div className="item" key={index}>
                                                  <DComponent setStateOfParent={this.setStateOfParent} title={product} key={index} value={index} onClick={this.addToCart} />
                                                  </div>
                                            )

                                          }

                                        })}

                                      </div>

                                      {(this.state.products.length > 4) &&
                                        <div className="ant-carousel-arrow-left leftLst"></div>
                                       }
                                      {(this.state.products.length > 4) &&
                                        <div className="ant-carousel-arrow-right rightLst"></div>
                                      }

                                  </div>
                                </div>
                              </div>
                          )}
                        </div>
                    </section>
                  </div>
                </div>
          </div>
        </section>
      </div>
    );
  }
}
export default MostView;
