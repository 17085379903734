import React, { Component } from 'react';
import $ from 'jquery';
import arrows from '../../assets/images/arrows.png';

import {NavLink} from 'react-router-dom';
import {api_url} from '../../Config.js';
import DComponent from './DynamicComponent.js';
import LComponent from './LoadComponent.js';
import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import * as ReactBootStrap from 'react-bootstrap';
const Container = styled.div`
  background-color:#444;
  color:white;
  padding:16px;
  position:fixed;
  top:${props=> props.top}px;
  right:16px;
  z-index:999;
  transition: top 0.9s ease;
`;
class FleshSlae extends Component {
    constructor(props) {
    super(props);
    this.state = {
      error: null,
      products: [],
      backupproducts: [],
      cartProducts:[],
      totalProducts:0,
      brands:[],
      itemShow:10,
      top:-8000,
      categories:[],
      productsFilters:[],
       filter:[],
      totalItems:"",
      loading:false,
      addtocart:'',
      itemquantity:1,
      items: [],
      cartCount:localStorage.getItem('cartCount'),
      base_url:api_url.base_url,
      img_url:api_url.img_url,
      com_url:api_url.com_url,
      filterData:[],
      fillterCategory:'',
      filterOption:'',
      settings:[],
      sale_view:1,
      sale_enable:1,
      error:'',
    }
    this.loadmore = this.loadmore.bind(this);
    this.viewAllProducts = this.viewAllProducts.bind(this);
    this.setStateOfParent = this.setStateOfParent.bind(this);
  }
  async setStateOfParent (result) {

      this.props.setStateOfParent(result);
      await this.setState({
        cartCount:result
      },()=>{
        localStorage.setItem('cartCount',result);
        localStorage.setItem('cartCount',result);
      })
    }
  async componentDidMount() {
    const settingUrl =this.state.com_url+'moiz_api/get_admin_settings';
    await fetch(settingUrl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            sale_view:result[0].flesh_sale_view,
            sale_enable:result[0].flesh_sale_enable,
          });
        },
        (error) => {
          this.setState({ error });
        }
      )



    var filterId = this.state.filterId;
    const apiUrl = this.state.base_url+'products_api/getProducts/all/all/all/flash_sale';
    this.setState({products:[],backupproducts:[],totalProducts:0});
    var api_Data = {user_id:localStorage.getItem('userData')};
    await fetch(apiUrl,{
          method: 'POST',
          headers:
          {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body:JSON.stringify(api_Data)
        })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            products: result.products,
            backupproducts:result.products,
            loading:false,
            totalProducts:result.total,
          });
        },
        (error) => {
          this.setState({ error });
        }
      )
        function getTimeRemaining(endtime) {
      var t = Date.parse(endtime) - Date.parse(new Date());
      var seconds = Math.floor((t / 1000) % 60);
      var minutes = Math.floor((t / 1000 / 60) % 60);
      var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
      var days = Math.floor(t / (1000 * 60 * 60 * 24));
      return {
        'total': t,
        'days': days,
        'hours': hours,
        'minutes': minutes,
        'seconds': seconds
      };
    }
    function initializeClock(id, endtime) {
      var clock = document.getElementById(id);
      if(document.getElementById(id)!=null)
      {
         var daysSpan = clock.querySelector('.days');
        var hoursSpan = clock.querySelector('.hours');
        var minutesSpan = clock.querySelector('.minutes');
        var secondsSpan = clock.querySelector('.seconds');
      }
      function updateClock() {
        var t = getTimeRemaining(endtime);
        if(daysSpan!=undefined)
        {
          daysSpan.innerHTML = t.days;
          hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
          minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);
          secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);
          if (t.total <= 0) {
            clearInterval(timeinterval);
          }

        }
      }
      var timeinterval = setInterval(updateClock, 1000);
    }
    var deadline = new Date(Date.parse(new Date()) + '01' * '24' * '60' * '60' * '1000');
    initializeClock('clockdiv', deadline);
    }
 async viewAllProducts()
  {
    const apiUrl = this.state.base_url+'products_api/getProducts/all';
   this.setState({products:[],backupproducts:[],totalProducts:0});
    await fetch(apiUrl)
      .then(res => res.json())
      .then(
        (result) => {

          this.setState({
            products: result.products,
            backupproducts:result.products,
            loading:false,
            totalProducts:result.total,
          });
        },
        (error) => {
          this.setState({ error });
        }
      )
  }


 async loadmore(){
      var length = parseInt(this.state.products.length) + 10;
      const apiUrl = this.state.base_url+'products_api/getProducts/'+length;
       this.state.products=[];
       this.state.backupproducts=[];
        await fetch(apiUrl)
          .then(res => res.json())
          .then(
            (result) => {
              this.setState({
                products: result.products,
                backupproducts:result.products,
                loading:false,
                totalProducts:result.total,
              });
            },
            (error) => {
              this.setState({ error });
            }
          )

      // this.setState((old)=>{
      //   return {itemShow:old.itemShow + 10}
      // })
  }


   componentDidUpdate(){


     var itemsMainDiv = ('.MultiCarousel');
    var itemsDiv = ('.MultiCarousel-inner');
    var itemWidth = "";

    $('.leftLst, .rightLst').click(function () {
        var condition = $(this).hasClass("leftLst");
        if (condition)
            click(0, this);
        else
            click(1, this)
    });

    ResCarouselSize();




    $(window).resize(function () {
        ResCarouselSize();
    });

    //this function define the size of the items
    function ResCarouselSize() {
        var incno = 0;
        var dataItems = ("data-items");
        var itemClass = ('.item');
        var id = 0;
        var btnParentSb = '';
        var itemsSplit = '';
        var sampwidth = $(itemsMainDiv).width();
        var bodyWidth = $('body').width();
        $(itemsDiv).each(function () {
            id = id + 1;
            var itemNumbers = $(this).find(itemClass).length;
            btnParentSb = $(this).parent().attr(dataItems);
            itemsSplit = btnParentSb.split(',');
            $(this).parent().attr("id", "MultiCarousel" + id);


            if (bodyWidth >= 1200) {
                incno = itemsSplit[3];
                itemWidth = sampwidth / incno;
            }
            else if (bodyWidth >= 992) {
                incno = itemsSplit[2];
                itemWidth = sampwidth / incno;
            }
            else if (bodyWidth >= 768) {
                incno = itemsSplit[1];
                itemWidth = sampwidth / incno;
            }
            else {
                incno = itemsSplit[0];
                itemWidth = sampwidth / incno;
            }
            $(this).css({ 'transform': 'translateX(0px)', 'width': itemWidth * itemNumbers });
            $(this).find(itemClass).each(function () {
                $(this).outerWidth(itemWidth);
            });

            $(".leftLst").addClass("over");
            $(".rightLst").removeClass("over");

        });
    }


    //this function used to move the items
    function ResCarousel(e, el, s) {
        var leftBtn = ('.leftLst');
        var rightBtn = ('.rightLst');
        var translateXval = '';
        var divStyle = $(el + ' ' + itemsDiv).css('transform');
        var values = divStyle.match(/-?[\d\.]+/g);
        var xds = Math.abs(values[4]);
        if (e == 0) {
            translateXval = parseInt(xds) - parseInt(itemWidth * s);
            $(el + ' ' + rightBtn).removeClass("over");

            if (translateXval <= itemWidth / 2) {
                translateXval = 0;
                $(el + ' ' + leftBtn).addClass("over");
            }
        }
        else if (e == 1) {
            var itemsCondition = $(el).find(itemsDiv).width() - $(el).width();
            translateXval = parseInt(xds) + parseInt(itemWidth * s);
            $(el + ' ' + leftBtn).removeClass("over");

            if (translateXval >= itemsCondition - itemWidth / 2) {
                translateXval = itemsCondition;
                $(el + ' ' + rightBtn).addClass("over");
            }
        }
        $(el + ' ' + itemsDiv).css('transform', 'translateX(' + -translateXval + 'px)');
    }

    //It is used to get some elements from btn
    function click(ell, ee) {
        var Parent = "#" + $(ee).parent().attr("id");
        var slide = $(Parent).attr("dataslide");
        ResCarousel(ell, Parent, slide);
    }

}


  render() {
    const {error, products} = this.state;
    // if(this.state.sale_enable == 0)
    // {
    //   return null;
    // }
    if(this.state.products.length === 0 || this.state.error !=='')
    {
      return <LComponent />;
    }
    return(
      <div>
        <Container top={this.state.top}>{this.state.addtocart}</Container>
        <section className="tabs_blog new_add_products">
              <div className="container">
                <div className="row">
                      <div className="col-sm-12 new_arrivals">
                        <section className="best_deal">
                            <div className="main_head">
                              <h3>Flash Sale</h3>
                            </div>
                            <div className="section-title">
                              <div className="bar"></div>
                            </div>
                              <div className="shadow_box">
                              <div className="row">
                                <div className="col-sm-3 end_time">
                                  <p>On Sale Now <span>Ending in</span></p>
                                </div>
                                <div className="col-sm-9">
                                  <div id="clockdiv">
                                  <div>
                                    <span className="days"></span>
                                    <div className="smalltext">Days</div>
                                  </div>
                                  <div>
                                    <span className="hours"></span>
                                    <div className="smalltext">Hours</div>
                                  </div>
                                  <div>
                                    <span className="minutes"></span>
                                    <div className="smalltext">Minutes</div>
                                  </div>
                                  <div>
                                    <span className="seconds"></span>
                                    <div className="smalltext">Seconds</div>
                                  </div>
                                </div>
                                </div>
                                <div className="withoutSlider">
                                  {
                                  (this.state.sale_view == 1) ?

                                      products.map((product,index) =>{
                                              if(product.is_homepage==='1')
                                              {
                                              return(
                                                      <DComponent setStateOfParent={this.setStateOfParent} show={true} title={product} display="none" key={index} user_id={localStorage.getItem('userData')} value={index} onClick={this.addToCart} />
                                                )

                                              }

                                            })
                                    :""
                                }
                                </div>
                                  <div className="read_more_btn">
                                  {
                                      this.state.totalProducts > this.state.products.length ?
                                        <div className="read_more_btn">
                                          <button onClick={this.viewAllProducts} type="button">View All</button>
                                        </div>
                                      :""
                                  }
                                  </div>
                              </div>
                              {(
                                (this.state.sale_view == 2) ?

                                  <div className="shadow_box">

                                    {this.state.products ?

                                      <React.Fragment>

                                    <div className="row">
                                        <div className="MultiCarousel" data-items="1,2,3,4" dataslide="1" id="MultiCarousel"  data-interval="1000">
                                            <div className="MultiCarousel-inner">
                                            {products.map((product,index) => {

                                              if(product.is_homepage==='1')
                                              {
                                                // console.log(product)
                                              return(
                                                      <div className="item" key={index}>
                                                      <DComponent setStateOfParent={this.setStateOfParent} show={true} title={product} key={index} value={index} onClick={this.addToCart} />
                                                      </div>
                                                )

                                              }

                                            })}

                                            </div>

                                            {(this.state.products.length > 4) &&
                                              <div className="ant-carousel-arrow-left leftLst"><i class="fa fa-angle-left"></i></div>
                                             }
                                            {(this.state.products.length > 4) &&
                                              <div className="ant-carousel-arrow-right rightLst"><i class="fa fa-angle-right"></i></div>
                                            }

                                        </div>
                                      </div>
                                      {(this.state.products.length > 20) &&
                                              <div className="read_more_btn">
                                      <NavLink to="/search-results/all/all/flash-sale" target="_blank"  className="show_all_button">Show All</NavLink>
                                      </div>
                                            }


                                      </React.Fragment>
                                      : <LComponent  setStateOfParent={this.setStateOfParent} show={false} />
                                    }
                                    </div>
                                :""
                              )}
                            </div>
                        </section>
                      </div>
                    </div>
              </div>
        </section>
      </div>
    );
  }
}
export default FleshSlae;
