import React, { Component } from 'react';
import DComponent from './DynamicComponent.js';
import {NavLink} from 'react-router-dom';
import {api_url} from '../../Config.js';
import styled from 'styled-components';
import Loader from '../../assets/images/loading.gif';
import * as ReactBootStrap from 'react-bootstrap';
import $ from 'jquery';
import LComponent from './LoadComponent.js';
const Container = styled.div`
  background-color:#444;
  color:white;
  padding:16px;
  position:fixed;
  top:${props=> props.top}px;
  display:${props=> props.display};
  right:16px;
  z-index:999;
  transition: top 0.9s ease;
`;

class Topcategory extends Component {
 constructor(props) {
    super(props);
    this.state = {
      error: null,
      products: [],
      backupproducts: [],
      cartProducts:[],
      totalProducts:0,
      brands:[],
      itemShow:10,
      top:-8000,
      display:'none',
      categories:[],
      productsFilters:[],
       filter:[],
      totalItems:"",
      loading:false,
      addtocart:'',
      itemquantity:1,
      items: [],
      cartCount:localStorage.getItem('cartCount'),
      filterId:localStorage.getItem('filterId'),
      base_url:api_url.base_url,
      img_url:api_url.img_url,
      filterData:[],
      fillterCategory:'',
      filterOption:'',
      com_url:api_url.com_url,
      sale_enable:1,
      sale_view:1,
      settings:[],
      topCategories:[],
      topCategoryProducts:[],
      current_tab_id:'',
      setLoading:false,
      error:'',
    }
    this.addToCart = this.addToCart.bind(this);
    this.getTopCategoryProduct = this.getTopCategoryProduct.bind(this);
    this.setStateOfParent = this.setStateOfParent.bind(this);
  }
async setStateOfParent (result) {

      this.props.setStateOfParent(result);
      await this.setState({
        cartCount:result
      },()=>{
        localStorage.setItem('cartCount',result);
        localStorage.setItem('cartCount',result);
      })
    }

   async componentDidUpdate() {
    $('ul.tabs_top li').click(function(){
        var tab_id = $(this).attr('data-tab');

        $('ul.tabs_top li').removeClass('current');
        // $('.tab-content_top').removeClass('current');
        $(this).addClass('current');
        $("#"+tab_id).addClass('current');
      })
     var itemsMainDiv = ('.MultiCarousel');
    var itemsDiv = ('.MultiCarousel-inner');
    var itemWidth = "";

    $('.leftLst, .rightLst').click(function () {
        var condition = $(this).hasClass("leftLst");
        if (condition)
            click(0, this);
        else
            click(1, this)
    });

    ResCarouselSize();




    $(window).resize(function () {
        ResCarouselSize();
    });

    //this function define the size of the items
    function ResCarouselSize() {
        var incno = 0;
        var dataItems = ("data-items");
        var itemClass = ('.item');
        var id = 0;
        var btnParentSb = '';
        var itemsSplit = '';
        var sampwidth = $(itemsMainDiv).width();
        var bodyWidth = $('body').width();
        $(itemsDiv).each(function () {
            id = id + 1;
            var itemNumbers = $(this).find(itemClass).length;
            btnParentSb = $(this).parent().attr(dataItems);
            itemsSplit = btnParentSb.split(',');
            $(this).parent().attr("id", "MultiCarousel" + id);


            if (bodyWidth >= 1200) {
                incno = itemsSplit[3];
                itemWidth = sampwidth / incno;
            }
            else if (bodyWidth >= 992) {
                incno = itemsSplit[2];
                itemWidth = sampwidth / incno;
            }
            else if (bodyWidth >= 768) {
                incno = itemsSplit[1];
                itemWidth = sampwidth / incno;
            }
            else {
                incno = itemsSplit[0];
                itemWidth = sampwidth / incno;
            }
            $(this).css({ 'transform': 'translateX(0px)', 'width': itemWidth * itemNumbers });
            $(this).find(itemClass).each(function () {
                $(this).outerWidth(itemWidth);
            });

            $(".leftLst").addClass("over");
            $(".rightLst").removeClass("over");

        });
    }


    //this function used to move the items
    function ResCarousel(e, el, s) {
        var leftBtn = ('.leftLst');
        var rightBtn = ('.rightLst');
        var translateXval = '';
        var divStyle = $(el + ' ' + itemsDiv).css('transform');
        var values = divStyle.match(/-?[\d\.]+/g);
        var xds = Math.abs(values[4]);
        if (e == 0) {
            translateXval = parseInt(xds) - parseInt(itemWidth * s);
            $(el + ' ' + rightBtn).removeClass("over");

            if (translateXval <= itemWidth / 2) {
                translateXval = 0;
                $(el + ' ' + leftBtn).addClass("over");
            }
        }
        else if (e == 1) {
            var itemsCondition = $(el).find(itemsDiv).width() - $(el).width();
            translateXval = parseInt(xds) + parseInt(itemWidth * s);
            $(el + ' ' + leftBtn).removeClass("over");

            if (translateXval >= itemsCondition - itemWidth / 2) {
                translateXval = itemsCondition;
                $(el + ' ' + rightBtn).addClass("over");
            }
        }
        $(el + ' ' + itemsDiv).css('transform', 'translateX(' + -translateXval + 'px)');
    }

    //It is used to get some elements from btn
    function click(ell, ee) {
        var Parent = "#" + $(ee).parent().attr("id");
        var slide = $(Parent).attr("dataslide");
        ResCarousel(ell, Parent, slide);
    }

   }

  async componentDidMount() {
    this.setState({setLoading:true})
    const settingUrl =this.state.com_url+'moiz_api/get_admin_settings';
    await fetch(settingUrl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            sale_view:result[0].featured_products_view,
            sale_enable:result[0].featured_products_enable,
          });
        },
        (error) => {
          this.setState({ error });
        }
      )

      const topCategoryUrl =this.state.base_url+'products_api/getTopCategory';
      await fetch(topCategoryUrl)
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              topCategories:result.topCategories,
            });

            const apiUrltopCategoryProducts = this.state.base_url+'products_api/getProducts/15/'+result.topCategories[0].id;
            var tabs = "tab-"+''+result.topCategories[0].id;
            this.setState({topCategoryProducts:[],current_tab_id:tabs});
            var api_Data = {user_id:sessionStorage.getItem('userData')};
            fetch(apiUrltopCategoryProducts,{
                method: 'POST',
                headers:
                {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                },
                body:JSON.stringify(api_Data)
              })
            .then(res => res.json())
            .then(
              (result) => {
                this.setState({
                  topCategoryProducts: result.products,
                  setLoading:false,
                });
              },
              (error) => {
                this.setState({ error, setLoading:false, });
              }
            )
          },
          (error) => {
            this.setState({ error,setLoading:false, });
          }
        )


    var filterId = this.state.filterId;

    const apiUrl = this.state.base_url+'products_api/getProducts/all/all/all/featuredproducts';

    this.setState({products:[],backupproducts:[],totalProducts:0});
    var api_Data = {user_id:sessionStorage.getItem('userData')};
    await fetch(apiUrl,{
          method: 'POST',
          headers:
          {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body:JSON.stringify(api_Data)
        })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            products: result.products,
            backupproducts:result.products,
            loading:false,
            totalProducts:result.total,
          });
        },
        (error) => {
          this.setState({ error });
        }
      )
  }
  getTopCategoryProduct=(e,top_cate_id)=>
  {
    this.setState({loading:true});
    const apiUrl = this.state.base_url+'products_api/getProducts/all/'+top_cate_id;
    var tabs = "tab-"+''+top_cate_id;
    this.setState({topCategoryProducts:[],current_tab_id:tabs});
    var api_Data = {user_id:sessionStorage.getItem('userData')};
     fetch(apiUrl,{
        method: 'POST',
        headers:
        {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body:JSON.stringify(api_Data)
      })
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          topCategoryProducts: result.products,
          loading:false,
        });
      },
      (error) => {
        this.setState({ error });
      }
    )
  }
  addToCart(e){
    this.setState((old)=>{
      return {cartCount:old.cartCount + 1}
    } )
  // localStorage.clear();
    this.setState({
          top:5,
        },function(){
          setTimeout(()=>{
            this.setState({
              top: -800,
            })
          },1500)
        })

        this.setState({addtocart:"Product Added to Cart!"})
        var count = this.state.cartCount;
        count = parseInt(this.state.cartCount)+parseInt(1);
        this.setState({cartCount:count},function(){})

        localStorage.setItem('cartCount',count);
        var newproducts = JSON.parse(localStorage.getItem('session'))
        var products =  this.state.products[e.target.name] ;
        var proid=products.id



        products['itemQty']='1';
        a = JSON.parse(localStorage.getItem('session')) || [];
        a.push(products);
        localStorage.setItem('session', JSON.stringify(a));

        for(var i= 0 ; i<newproducts.length ; i++)
        {
          var newid = newproducts.map((key,index)=>(key.id))
          var idd = newproducts[i]
          if(products.id == idd.id)
          {

            const updateditems = newproducts.filter(item=>item.id !==idd.id);

            localStorage.setItem('session', JSON.stringify(updateditems));


            var changeproduct = newproducts[i]

            var qty = changeproduct.itemQty

            products['itemQty']=parseInt(changeproduct.itemQty)+ parseInt(1);
            var a = [];
              a = JSON.parse(localStorage.getItem('session')) || [];
              var length = a.length;
              a.push(products);
              localStorage.setItem('session', JSON.stringify(a));

          }
        }
    }
 render() {
    const {error, products} = this.state;
    if(this.state.sale_enable == 0)
    {
      return null;
    }
    if(this.state.products.length === 0 || this.state.error !=='')
    {
      return <LComponent />;
    }
    const topCategories=[];
    if(this.state.topCategories.length > 0)
		{
			this.state.topCategories.forEach((element, index) => {
        var tabs = "tab-"+''+element.id;
        if(index == 0)
        {
          topCategories.push(
            <li key={index} onClick={e=>this.getTopCategoryProduct(e,element.id)} className="tab-link_top current" data-tab={tabs}>{element.groupName}</li>
          );
        }
        else
        {
          topCategories.push(
              <li key={index} className="tab-link_top" onClick={e=>this.getTopCategoryProduct(e,element.id)} data-tab={tabs}>{element.groupName}</li>
          );
        }
			})
		}
    return(
      <div>
        <Container top={this.state.top}>{this.state.addtocart}</Container>
        <section className="tabs_blog new_add_products">
          <div className="container">
            <div className="row">
                  <div className="col-sm-12 new_arrivals">
                    <section className="best_deal">
                        <div className="main_head">
                          <h3>Top Categories</h3>
                        </div>
                        <div className="section-title">
                          <div className="bar"></div>
                        </div>
                        {
                          topCategories ===null ? <LComponent /> :
                          <div className="shadow_box">
                        <div className="top_category_tabs">
                        <ul className="tabs_top">
                          {topCategories}
                        </ul>
                          <div id={this.state.current_tab_id} className="tab-content_top current">
                          <div className="shadow_box">
                            <div className="row">

                            {(this.state.loading) &&
                              <div style={{margin:'0 auto'}}>
                                <img src={Loader} /></div>

                            }
                                {
                                  <div className="MultiCarousel"  data-items="1,2,3,4" dataslide="1" id="MultiCarousel"  data-interval="1000">
                                    <div className="MultiCarousel-inner">
                                        {this.state.topCategoryProducts.map((product,index) => {
                                          if(product.is_homepage==='1')
                                          {
                                          return(
                                                  <div className="item" key={index}>
                                                      <DComponent setStateOfParent={this.setStateOfParent} title={product} key={index} value={index}/>
                                                  </div>
                                            )

                                          }


                                        })}

                                    </div>
                                    {(this.state.topCategoryProducts.length >4) &&
                                      <div className="ant-carousel-arrow-left leftLst"><i class="fa fa-angle-left"></i></div>
                                      }
                                    {(this.state.topCategoryProducts.length >4) &&
                                      <div className="ant-carousel-arrow-right rightLst"><i class="fa fa-angle-right"></i></div>
                                    }
                                </div>
                                }
                              </div>
                              {
                                this.state.topCategoryProducts.length >20 &&
                                <div className="read_more_btn">
                                  <NavLink to={`/search-results/${this.state.current_tab_id}/all/all`} target="_blank" className="show_all_button">Show All</NavLink>
                              </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                        }
                    </section>
                  </div>
                </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Topcategory;
