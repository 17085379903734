import React, { Component } from 'react';
import $ from 'jquery';
import Header  from "./Header/Header";
import Footer  from "./Footer/Footer";
import {Redirect} from 'react-router-dom';
import {PostData} from '../services/PostDataCheck.js';
import {api_url} from '../Config.js';
import swal from 'sweetalert';
import loading from '../assets/images/loading.gif';
import {NavLink} from 'react-router-dom';
class Checkout extends Component {
    constructor(props) {
    super(props);
    this.state = {
      com_url:api_url.com_url,
      data:[],
      redirectToReferrer: false,
      items: JSON.parse(localStorage.getItem('cartProducts')),
      dropShip: (localStorage.getItem('dropshipProduct')) ?JSON.parse(localStorage.getItem('dropshipProduct')) : [],
      is_dropship:(localStorage.getItem('isdropship')),
      subtotal:0,
      grandtotal:0,
      prefix:'',
      suffix:'',
      currency:'',
      firstname:"",
      lastname:"",
      address:"",
      country:"",
      street:"",
      city:"",
      appartment:"",
      town:'',
      postcode:"",
      phone:"",
      email:"",
      notes:"",
      user_id:localStorage.getItem('userData'),
      base_url:api_url.base_url,
      userDetails:[],
      itemsErr:'',
      firstnameErr:'',
      addressErr:'',
      emailErr:'',
      lastnameErr:'',
      countryErr:'',
      phoneErr:'',
      cityErr:'',
      PostcodeErr:'',
      streetErr:'',
      countryList:[],
      states:[],
      cities:[],
      city_id:'',
      state_id:'',
      country_id:'',
      loading:false,
      total_weight:0,
      shipping_cost:0,
      customer_type:0,
      shipping_address:'',
      shippingAddresses:[],
      save_shipping:0,
      disbaleCheck:false,
      province:'',
    };
    this.placeOrder=this.placeOrder.bind(this);
    this.order_validat=this.order_validat.bind(this);
    // this.getChangeAddress=this.getChangeAddress.bind(this);
}
async componentDidMount(){
    this._isMounted = true;
  if(this.state.items!=null)
  {
    var total_weight = 0;
    for(var i in this.state.items)
    {
      var singleItem = this.state.items[i];
      if(singleItem.weight)
      {
        total_weight=parseFloat(total_weight)+parseFloat(singleItem.weight);
      }
    }
    this.setState({total_weight:total_weight});
    // alert(total_weight);
  }
    (this.state.items == null) ? this.setState({subtotal:0}):
    this.state.items.map(item=>(
    this.setState((old)=>{
      if(item.discounted_price > 0)
      {
        return {subtotal: (old.subtotal) + ((item.discounted_price * item.itemQty))}
      }
      else
      {
        return {subtotal: (old.subtotal) + (item.saleRate)*(item.itemQty)}
      }
    })
  ))

  if(localStorage.getItem('userData'))
  {
    var user_id = localStorage.getItem('userData');
    const  prefixUrl = this.state.com_url+'moiz_api/getCustomerDetail/'+user_id;
     await fetch(prefixUrl)
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              customer_type: result.customer_detail.customer_type,
            }, function(){
            });
          },
          (error) => {
            this.setState({ error });
          }
        )
  }

  if(localStorage.getItem('userData'))
  {
    var user_id = localStorage.getItem('userData');
    const  shippingUrl = this.state.com_url+'moiz_api/getAllCountries?user_id='+user_id;
     await fetch(shippingUrl)
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              shippingAddresses: result.address,
            }, function(){
            });
          },
          (error) => {
            this.setState({ error });
          }
        )
  }


   const settingUrl =this.state.com_url+'moiz_api/get_general_settings';
    await fetch(settingUrl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
           country: result.country,
           states:result.state,
          })
        },
        (error) => {
          this.setState({ error });
        }
      )
    const countryListUrl =this.state.com_url+'moiz_api/getCountryLsit';
    await fetch(countryListUrl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
           countryList: result
          })
        },
        (error) => {
          this.setState({ error });
        }
      )
    $(".collapsed").click(function(){
      $(".collapse_info").slideToggle();
  });

  const userId = localStorage.getItem('userData');

   const getDetails = this.state.base_url+`products_api/getUserDetails?userId=${userId}`;

   fetch(getDetails)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            userDetails: result

          }, function(){

          });

        },
        (error) => {
          this.setState({ error });
        }
      )

   const prefixUrl = this.state.base_url+'products_api/GetPrefix';

    fetch(prefixUrl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            prefix: result

          }, function(){

          });

        },
        (error) => {
          this.setState({ error });
        }
      )
  const suffixUrl = this.state.base_url+'products_api/GetSuffix';

    fetch(suffixUrl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            suffix: result

          }, function(){

          });
        },
        (error) => {
          this.setState({ error });
        }
      )
      const currencyUrl = this.state.base_url+'products_api/GetCurrency';

    fetch(currencyUrl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            currency: result

          }, function(){

          });
        },
        (error) => {
          this.setState({ error });
        }
      )
}

changeAddress(e)
{

  this.setState({shipping_address:e.target.value},function(){
    console.log(this.state.shipping_address)
  const adURL = this.state.base_url+'products_api/getSelectedAddress/'+this.state.shipping_address;

    fetch(adURL)
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
          this.setState({
            appartment:result[0].appartment,
            street:result[0].street,
            postcode:result[0].postal_code,
            phone:result[0].phone_number,
            city:result[0].city,
            province:result[0].state,
            country:result[0].country,
            disbaleCheck:true
          }, function(){
            console.log(this.state)
          });

        },
        (error) => {
          this.setState({ error });
        }
      )
  })

}
order_validat(){
  if(this.state.items==null || this.state.items.length==0)
  {
      this.setState({itemsErr:'Your Cart is empty'})
      swal('Your Cart is empty')

  }
  if(this.state.firstname ==''){
      this.setState({firstnameErr:'Please enter your First Name'});
      return false;
  }else{
    this.setState({firstnameErr:''});
  }
  if(this.state.lastname ==''){
      this.setState({lastnameErr:'Please enter your Last Name'});
      return false;
  }else{
    this.setState({lastnameErr:''});
  }
  if(this.state.email ==''){
      this.setState({emailErr:'Please enter your Email Address'});
      return false;
  }else{
    this.setState({emailErr:''});
  }
  if(this.state.country ==''){
      this.setState({countryErr:'Please enter your Country Name'});
      return false;
  }else{
    this.setState({countryErr:''});
  }
  if(this.state.street=='' && this.state.customer_type !=1){
      this.setState({streetErr:'Please enter your Street Address'});
      return false;
  }else{
    this.setState({streetErr:''});
  }
  if(this.state.town == '' || this.state.town == 'Select a city'){
      this.setState({cityErr:'Please select state first then city'});
      return false;
  }else{
    this.setState({cityErr:''});
  }
  if(this.state.postcode == ''){
      this.setState({PostcodeErr:'Please enter your post code'});
      return false;
  }else{
    this.setState({PostcodeErr:''});
  }
  if(this.state.phone==''){
      this.setState({phoneErr:'Please enter your Phone Number'});
      return false;
  }else{
    this.setState({phoneErr:''});
    return true;
  }
  // if(this.state.address =='' && this.state.customer_type !=1)
  // {
  //   this.setState({addressErr:'Please Enter your Complete Address'});
  //   return false;
  // } else{
  //   return true
  // }
}
placeOrder(){
  var firstname = $('#firstname').val();
  var lastname = $('#lastname').val();
  var email = $('#email').val();
  var address = $('#address').val();
  var postcode = $('#postcode').val();
  var phone = $('#phone').val();
  var country = $('#country').val();
  var town = $('#city').val();
  var state_id = $('#state_id').val();
    this.setState({
      firstname:firstname,
      lastname:lastname,
      email:email,
      address:address,
      postcode:postcode,
      phone:phone,
      town:town,
      state_id:state_id,
    },function(){
       if(this.order_validat())
        {
            this.setState({
                firstnameErr:'',
                lastnameErr:'',
                emailErr:'',
                cityErr:'',
                addressErr:'',
                phoneErr:'',
                streetErr:'',
                countryErr:'',
                PostcodeErr:'',
            })

            console.log(this.state)
            // PostData(this.state);
            // swal('Thanks for placeing Order')
            this.setState({items:null,subtotal:null})
            localStorage.clear();
            localStorage.setItem('success',"Your order has been successfully posted! Thanks for purchasing");
        }
    })

}
getCountryId=(e)=>
{
  this.setState({country_id:e.target.value});
  var country_id=e.target.value;
   const getstate = this.state.com_url+'moiz_api/getState/'+e.target.value;
   this.setState({loading:true})
    fetch(getstate)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            states: result,
            loading:false,
            country:country_id
          })
        },
        (error) => {
          this.setState({ error });
        }
      )
}
getCityId=(e)=>
{
  this.setState({city_id:e.target.value});
  this.getGetWeightPrice(e,e.target.value);

}
getGetWeightPrice = (e,city_id) =>
{
  const weightUrl =this.state.com_url+'moiz_api/get_weight_setting';
  var post_data = {weight:this.state.total_weight,city_id:city_id};
   fetch(weightUrl,{
        method: 'POST',
        headers:
        {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body:JSON.stringify(post_data)
      })
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({shipping_cost:result.shipping_cost})
      },
      (error) => {
        this.setState({ error });
      }
    )
}
getStateId=(e)=>
{
  this.setState({state_id:e.target.value});
   const getCity = this.state.com_url+'moiz_api/getCity/'+e.target.value;
    fetch(getCity)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            cities: result
          })
        },
        (error) => {
          this.setState({ error });
        }
      )
}

onChangeSave = (e) =>
{
  let isChecked = e.target.checked;
  if(isChecked){
    this.setState({save_shipping:'1'})
  }else{
    this.setState({is_default:'0'})
  }
}

render() {
  if(!localStorage.getItem('userData'))

  if(localStorage.getItem('success'))
  {
    return <Redirect to='/'  />;
  }
  const shippingArray = [];
  if(this.state.shippingAddresses.length > 0)
  {
    this.state.shippingAddresses.forEach((element, index) => {
      shippingArray.push(
          <option value={element.id} key={index}>{element.street +' '+element.appartment+' '+element.city+' '+element.province}</option>
        );
      })
  }

  const countryarray = [];
  if(this.state.countryList.length > 0)
  {
    this.state.countryList.forEach((element, index) => {
      countryarray.push(
          <option value={element.id} key={index}>{element.name}</option>
        );
      })
  }

  const state_array = [];
  if(this.state.states!=undefined)
  {
    this.state.states.forEach((element, index) => {
      state_array.push(
          <option value={element.id} key={index} selected={element.id===this.state.province}>{element.name}</option>
        );
      })
  }

  const city_array = [];
  if(this.state.cities.length > 0)
  {
    this.state.cities.forEach((element, index) => {
      city_array.push(
          <option value={element.name} key={index} selected={element.name===this.state.city}>{element.name}</option>
        );
      })
  }


    return (
            <div>
            <Header />

                <div id="main" className="column1 boxed checkout_page">
                   <div className="container">
                      <div className="row main-content-wrap">
                         <div className="main-content col-lg-12">
                            <div id="content" role="main">
                               <article className="post-209 page type-page status-publish hentry">
                                  <div className="page-content">
                                     <div className="woocommerce">
                                <form name="checkout" method="post" className="checkout woocommerce-checkout" action="#" encType="" noValidate="novalidate">
                                   <div className="row" id="billing_details">
                                      <div className="col-lg-4" id="customer_details">
                                         <div className="woocommerce-billing-fields clearfix">
                                            <h3>Customer details</h3>

                                            <div className="woocommerce-billing-fields__field-wrapper">

                                              <p className="form-row form-row-wide margin_none">
                                                <label htmlFor="billing_postcode">Email address *</label><NavLink to="/login" style={{marginLeft:'86px'}} className="already_account"> Already have account</NavLink>
                                                  <input id="email" type="email" defaultValue={(this.state.userDetails != null)?this.state.userDetails.email : ''} className="input-text" name="email" onChange={(e)=>this.setState({email:e.target.value})} />
                                                  <span style={{'color':'red'}}>{this.state.emailErr}</span>
                                                </p>
                                               <p className="form-row form-row-first">
                                                <label htmlFor="billing_first_name">First Name *</label>
                                                <input id="firstname" type="text" className="input-text" defaultValue={(this.state.userDetails!= null)?this.state.userDetails.first_name : ''} name="firstname" onChange={(e)=>this.setState({firstname:e.target.value})} />
                                                <span style={{'color':'red'}}>{this.state.firstnameErr}</span>
                                              </p>
                                              <p className="form-row form-row-last">
                                                <label htmlFor="billing_first_name">Last Name *</label>
                                                <input type="text" defaultValue={(this.state.userDetails!= null)?this.state.userDetails.last_name : ''} id="lastname" className="input-text" name="lastname" onChange={(e)=>this.setState({lastname:e.target.value})} />
                                                <span style={{'color':'red'}}>{this.state.lastnameErr}</span>
                                              </p>
                                        <p className="form-row form-row-wide">
                                                <label htmlFor="billing_first_name">Country *</label>
                                                <select id="country" value={this.state.country} className="form-control" name="country" onChange={e=>this.getCountryId(e)}>
                                                    <option defaultValue="">Select a country…</option>
                                                    {countryarray}
                                                </select>
                                                <span style={{'color':'red'}}>{this.state.countryErr}</span>
                                        </p>


                                        <p className="form-row form-row-wide form-row-first">
                                               <label htmlFor="billing_first_name">State *</label>
                                            <select id="state_id" className="form-control" name="state" onChange={e=>this.getStateId(e)}>
                                                <option defaultValue="">Select a State</option>
                                                {state_array}
                                            </select>
                                              </p>
                                              <p className="form-row form-row-wide form-row-last">
                                                <label htmlFor="billing_first_name">City *</label>
                                              <select id="city" className="form-control" name="city" onChange={e=>this.getCityId(e)}>
                                                  <option defaultValue="">Select a city</option>
                                                  {city_array}
                                              </select>
                                              <span style={{'color':'red'}}>{this.state.cityErr}</span>
                                              </p>


                                              <p className="form-row form-row-wide margin_none">
                                                <label htmlFor="billing_first_name">Street *</label>
                                                <input type="text" className="input-text" placeholder="House number and street name" name="street" value={this.state.street} id="street" onChange={(e)=>this.setState({street:e.target.value})}/>
                                                <span style={{'color':'red'}}>{this.state.streetErr}</span>
                                              </p>
                                              <p className="form-row form-row-wide margin_none">
                                                <label htmlFor="billing_first_name">Apartment, suite, unit etc. <span className="optional">(optional)</span></label>
                                                <input type="text" className="input-text" placeholder="Apartment, suite, unit etc." name="appartment" value={this.state.appartment} onChange={(e)=>this.setState({appartment:e.target.value})} />
                                              </p>
                                              <p className="form-row form-row-first margin_none">
                                                <label htmlFor="billing_postcode">Postcode </label>
                                                  <input type="text" className="input-text" id="postcode" name="postcode" value={this.state.postcode} onChange={(e)=>this.setState({postcode:e.target.value})} defaultValue={(this.state.userDetails!= null)?this.state.userDetails.tin : ''} />
                                                  <span style={{'color':'red'}}>{this.state.PostcodeErr}</span>
                                              </p>
                                              <p className="form-row form-row-last margin_none">
                                                <label htmlFor="billing_postcode">Phone *</label>
                                                  <input type="tel" className="input-text" name="phone"  value={this.state.phone} onChange={(e)=>this.setState({phone:e.target.value})} id="phone" defaultValue={(this.state.userDetails!= null)?this.state.userDetails.phone : ''} />
                                                  <span style={{'color':'red'}}>{this.state.phoneErr}</span>
                                              </p>

                                            </div>
                                         </div>

                                         <div className="woocommerce-shipping-fields">
                                         </div>
                                         <div className="woocommerce-additional-fields">
                                            <h3>Additional information</h3>
                                            <div className="woocommerce-additional-fields__field-wrapper">
                                               <p className="form-row notes" id="order_comments_field" >
                                                <label htmlFor="order_comments" className="">Order notes</label>
                                                <span className="woocommerce-input-wrapper">
                                                  <textarea className="input-text "  placeholder="Notes about your order, e.g. special notes for delivery." rows="2" cols="5" name="notes" onChange={(e)=>this.setState({notes:e.target.value})} ></textarea></span>
                                                </p>
                                            </div>
                                         </div>
                                      </div>
                                      <div className="checkout-order-review align-left col-lg-8">
                                        <div id="order_review" className="woocommerce-checkout-review-order">
                                          <div className="row">
                                             <div className="col-lg-6 order_review">
                                                <h3>Order Review</h3>
                                                <table className="shop_table review-order woocommerce-checkout-review-order-table">
                                                   <thead>

                                                      <tr>
                                                         <th className="product-name">Product</th>
                                                         <th className="product-total">Total</th>
                                                      </tr>
                                                   </thead>
                                                   <tbody>
                                                    {(this.state.items == null) ? <tr><td>Your Cart is empty</td></tr> :

                                                      this.state.items.map((item,index)=>(

                                                      <tr className="cart_item" key={index}>
                                                         <td className="product-name">
                                                            {item.product_name} ({item.unit_name}) <strong className="product-quantity"> × {item.itemQty}</strong>
                                                         </td>
                                                         <td className="product-total">
                                                           {(
                                                             item.discounted_price > 0 ?
                                                              <span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">{item.prefix}</span> {((item.discounted_price)*(item.itemQty)).toLocaleString()} {item.suffix}</span>
                                                             :
                                                              <span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">{item.prefix}</span> {((item.saleRate)*(item.itemQty)).toLocaleString()} {item.suffix}</span>

                                                           )}
                                                         </td>
                                                      </tr>
                                                      ))

                                                    }
                                                   </tbody>
                                                   <tfoot>
                                                      <tr className="cart-subtotal">
                                                         <th>Subtotal</th>
                                                         <td><span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">{this.state.prefix}</span>{this.state.subtotal.toLocaleString() ? this.state.subtotal.toLocaleString():0} {this.state.suffix}</span></td>
                                                      </tr>
                                                      {(
                                                          this.state.shipping_cost > 0 &&
                                                          <tr className="cart-subtotal">
                                                             <th>Shipping Cost</th>
                                                             <td><span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">{this.state.prefix}</span>{this.state.shipping_cost.toLocaleString() ? this.state.shipping_cost.toLocaleString():0} {this.state.suffix}</span></td>
                                                          </tr>
                                                      )}
                                                      <tr className="order-total">
                                                         <th>Total</th>
                                                         <td><strong><span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">{this.state.prefix}</span>{(parseFloat(this.state.subtotal) + parseFloat(this.state.shipping_cost)).toLocaleString()} {this.state.suffix}</span></strong> </td>
                                                      </tr>
                                                   </tfoot>
                                                </table>
                                             </div>
                                             <div className="col-lg-6 payment-method">
                                                <div id="payment" className="woocommerce-checkout-payment">
                                                   <h3>Payment Methods</h3>
                                                   <ul className="wc_payment_methods payment_methods methods">
                                                      <li className="woocommerce-notice woocommerce-notice--info woocommerce-info">Sorry, it seems that there are no available payment methods for your state. Please contact us if you require assistance or wish to make alternate arrangements.</li>
                                                   </ul>
                                                   <div className="form-row place-order">
                                                      <h3>Grand Total:
                                                         <span><strong><span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol"> {this.state.prefix}</span>{(parseFloat(this.state.subtotal)+parseFloat(this.state.shipping_cost)).toLocaleString()}</span>{this.state.suffix}</strong> </span>
                                                      </h3>
                                                      <a style={{'cursor':'pointer','textAlign':'center','background':'#225189'}} className="button alt" name="woocommerce_checkout_place_order" id="place_order" defaultValue="Place order" data-value="Place order" onClick={this.placeOrder}>Place Order</a>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                        </div>
                                      </div>
                                           </div>
                                        </form>
                                     </div>
                                  </div>
                               </article>
                            </div>
                         </div>
                         <div className="sidebar-overlay"></div>
                      </div>
                   </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Checkout;
