import React, { Component } from 'react';
import $ from 'jquery';
import "bootstrap/dist/css/bootstrap.min.css";
import '../../assets/css/bootstrap.css';
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/font-awesome.min.css';
import slider25852 from '../../assets/images/slider-25852.webp';
import banner1258 from '../../assets/images/banner1258-1200x0.jpg';
import slidercode from '../../assets/images/slider-code.gif';
import slider10587 from '../../assets/images/slider-10587.webp';
import '../../assets/css/style.css';
import {api_url} from '../../Config.js';
import {NavLink} from 'react-router-dom';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
class Banner extends Component {
	constructor(props){
		super(props);
		this.state = {
			categories:[],
			base_url:api_url.base_url,
      		img_url:api_url.img_url,
      		com_url:api_url.com_url,
      		cat_id:0,
      		homedata:'',
      		sliders:[],
      		banners:[],
		}
		this.getCategoryName = this.getCategoryName.bind(this);
	}
	componentDidMount(){
		const caturl = this.state.base_url+'products_api/GetCategories';
	    fetch(caturl)
	      .then(res => res.json())
	      .then(
	        (result) => {
	          this.setState({
	            categories: result

	          });
	        },
	        (error) => {
	          this.setState({ error });
	        }
	      )
	    const home_setting = this.state.com_url+'home_api/gethomesetting';
	    fetch(home_setting)
	      .then(res => res.json())
	      .then(
	        (result)=>{
	          this.setState({
	            sliders:result.data.sliders,
	            banners:result.data.banners,
	          });
	        },
	        (error) => {
	          this.setState({error});
	        }
	      )
	}

	getCategoryName(e){
		this.setState({
			cat_id:e.target.value
		})
	}

	render() {
		const banners=[];
		this.state.banners.forEach((element, index) => {
			var Background  = this.state.img_url+'/'+element.image;
			var sectionStyle = {
			  backgroundImage: "url("+Background+")"
			};
			banners.push(
				<div className="col-sm-4 tab_fullwidth" key={index}>
		      		<div className="deal_sneaker_info blue_bg" style={sectionStyle} >
		               <b>{element.title}</b>
		               <p>{element.sub_title}</p>
		               <a href="{element.link}">Shop Now</a>
		               <h5>{element.description}</h5>
		            </div>
	            </div>
            );
		})
		const sliders=[];
		if(this.state.sliders.length > 0)
		{
			this.state.sliders.forEach((element, index) => {
				if(index == 0)
				{
					sliders.push(
			      		<div className="item active" key={index}>
			      			<img src={`${this.state.img_url}/${element.slider_image}`} alt="" />
			      		</div>
		            );
				}
				else
				{
					sliders.push(
			      		<div className="item" key={index}>
			      			<img src={this.state.img_url+`/${element.slider_image}`} alt="" />
			      		</div>
		            );
				}
			})

		}
		if(sliders.length == 0)
		{
			sliders.push(
	      		<div className="item active" key={1}>
	      			<img src={slider25852} alt="" />
	      		</div>
            );
            sliders.push(
	      		<div className="item" key={2}>
	      			<img src={banner1258} alt="" />
	      		</div>
            );
            sliders.push(
	      		<div className="item" key={3}>
	      			<img src={slidercode} alt="" />
	      		</div>
            );
            sliders.push(
	      		<div className="item" key={4}>
	      			<img src={slider10587} alt="" />
	      		</div>
            );
		}
		return (
			<div>
			<section className="main_wrap">
			    <div className="container">
			        <div className="row">
			            <div className="col-lg-3 col-md-3 col-sm-3 sidebar_category scrollbar" >
			                <ul className="scrollbar" id="style-4">

			                	{this.state.categories.map((cat,index)=>(
			                		<NavLink to={`/search-results/${cat.id}/all/all`} key={index}>
			                			<li key={index} className="cl-item-women" value={cat.id} onClick={this.getCategoryName}>
			                				<img src={cat.icon_image || <Skeleton />} />
			                				{cat.groupName || <Skeleton />}
			                			</li>
			                      	</NavLink>
			                	))}

			                </ul>
			            </div>
			            <div className="col-lg-9 col-md-9 col-sm-12 slider_fullwidth">
			                <div className="slider_box">
			                    <div id="myCarousel" className="carousel slide" data-ride="carousel">

			                        <div className="carousel-inner">
		                        		{sliders}
			                        </div>
			                        <a className="left carousel-control" href="#myCarousel" data-slide="prev">
			                          <span className="glyphicon glyphicon-chevron-left"></span>
			                          <span className="sr-only">Previous</span>
			                        </a>
			                        <a className="right carousel-control" href="#myCarousel" data-slide="next">
			                          <span className="glyphicon glyphicon-chevron-right"></span>
			                          <span className="sr-only">Next</span>
			                        </a>
			                      </div>
			                </div>

			            </div>
			        </div>
			    </div>
			</section>


			<div className="deal_sneaker_wrap">
			   <div className="container">
			      <div className="row">
			      </div>
			   </div>
			</div>
		</div>
		);
	}
}

export default Banner;
