import React, {Component} from 'react';
import twitter from '../../assets/images/twitter.png';
import facebook from '../../assets/images/facebook.png';
import googleplus from '../../assets/images/google-plus.png';
import {PostData} from './PostData';
import {Redirect} from 'react-router-dom';
import {NavLink} from 'react-router-dom';
import Header from '../Header/Header.js';
import Footer from '../Footer/Footer.js';
import swal from 'sweetalert';
class Signup extends Component {
constructor(props){
super(props);
this.state = {
username: '',
password: '',
email: '',
name: '',
lastname: '',
redirectToReferrer: false,
fields: {},
errors: {},
already:'',
loading:false,
phoneno:'',
wholesale:0,
};
this.signup = this.signup.bind(this);
this.onChange = this.onChange.bind(this);
this.contactSubmit = this.contactSubmit.bind(this);
this.handleValidation = this.handleValidation.bind(this);
}
signup() {
  if(this.state.username && this.state.password && this.state.email && this.state.name && this.state.lastname){
  }
}
onChange(e){
  this.setState({[e.target.name]:e.target.value});
}
handleValidation(){
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    // Password
    if(!fields["password"]){
       formIsValid = false;
       errors["password"] = "You must need to enter a password";
    }
    //Name
    if(!fields["name"]){
       formIsValid = false;
       errors["name"] = "This feild cannot be empty";
    }
    if(typeof fields["name"] !== "undefined"){
       if(!fields["name"].match(/^[a-zA-Z]+$/)){
          formIsValid = false;
          errors["name"] = "This feild must contain only letters";
       }
    }
    // Last name
    if(!fields["lastname"]){
       formIsValid = false;
       errors["lastname"] = "This feild cannot be empty";
    }
    if(!fields["phoneno"]){
       formIsValid = false;
       errors["phoneno"] = "This feild cannot be empty";
    }
    if(typeof fields["lastname"] !== "undefined"){
       if(!fields["lastname"].match(/^[a-zA-Z]+$/)){
          formIsValid = false;
          errors["lastname"] = "This feild must contain only letters";
       }
    }
    //Email
    if(!fields["email"]){
       formIsValid = false;
       errors["email"] = "Email cannot be empty";
    }
    if(typeof fields["email"] !== "undefined"){
       let lastAtPos = fields["email"].lastIndexOf('@');
       let lastDotPos = fields["email"].lastIndexOf('.');
       if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
          formIsValid = false;
          errors["email"] = "Email is not valid";
        }
   }
   this.setState({errors: errors});
     return formIsValid;
   }
   contactSubmit(e){
        if(this.handleValidation()){
          this.setState({loading:true})
          PostData(this.state).then((result) => {
  				let responseJson = result;
    				if(responseJson.userData)
            {
      				sessionStorage.setItem('userData',JSON.stringify(responseJson.userData));
      				this.setState({redirectToReferrer: true});
    				}
    				else
            {
      				this.setState({already:result.error,loading:false},function(){
      				})
            }
  			});
      }
    }
    handleChange(field, e){
        this.setState({[e.target.name]:e.target.value});
        let fields = this.state.fields;
        fields[field] = e.target.value;

        this.setState({fields});
    }
     handleChangeCheck(field, e){
        let isChecked = e.target.checked;
        if(isChecked){
          this.setState({wholesale:'1'})
        }else{
          this.setState({wholesale:'0'})
        }
    }
render() {
  const {loading} = this.state;
if (this.state.redirectToReferrer) {
  return (<Redirect to={'/login'}/>)
}
return (
	<div>
		<Header />
				<div className="form_wrap" id="register_form">
				   <div className="container">
				      <div className="row middle_form">
				         <div className="col-sm-4 login_right_bg">
				            <div className="details_info">
				               <h3>Welcome, Back!</h3>
				               <p><span>To keep connected with us please </span> login with your personal info</p>
				               <NavLink to="/login">Sign In</NavLink>
				            </div>
				         </div>
				         <div className="col-sm-8 sign_up_left">
				            <div className="social_media">
				               <h3>Sign UP</h3>
				               {/*<p>Sign Up with social media</p>
				               <ul>
				                  <li><a href="#" target="_blank"><img src={twitter} alt="" /></a></li>
				                  <li><a href="#" target="_blank"><img src={facebook} alt="" /></a></li>
				                  <li><a href="#" target="_blank"><img src={googleplus} alt="" /></a></li>
				               </ul>*/}
				               {/*<span>Or use you email for Registration</span>*/}
				            </div>
				            <div className="sign_up_form">
      								<input type="text" name="name" placeholder="First Name" onChange={this.handleChange.bind(this, "name")} value={this.state.fields["name"]}/><span style={{color: "red"}}>{this.state.errors["name"]}</span>
      								<input type="text" name="lastname" placeholder="Last Name" onChange={this.handleChange.bind(this, "lastname")} value={this.state.fields["lastname"]}/><span style={{color: "red"}}>{this.state.errors["lastname"]}</span>


      				        <input type="text" name="email" placeholder="Email" onChange={this.handleChange.bind(this, "email")} value={this.state.fields["email"]}/><span style={{color: "red"}}>{ (this.state.errors["email"]) ? this.state.errors["email"] : this.state.already } </span>

                      <input type="text" name="phoneno" placeholder="Phone No" onChange={this.handleChange.bind(this, "phoneno")} value={this.state.fields["phoneno"]}/><span style={{color: "red"}}>{this.state.errors["phoneno"]}</span>

      								<input type="password" name="password" placeholder="Password" onChange={this.handleChange.bind(this, "password")} value={this.state.fields["password"]}/>
                      <span style={{color: "red"}}>{ (this.state.errors["password"]) ? this.state.errors["password"] : "" } </span>

                      <p class="checkbox" style={{padding:'0'}}>
                <label>
                 <input type="checkbox" onClick={this.handleChangeCheck.bind(this, "wholesale")} name='wholesale' />
                 <span class="lbl padding-8">I am wholesale user</span>
                 </label>
                 </p>


				               <div className="login_form">
                          <button
                             className="button-loadings"
                             style={{ fontSize: "18px"}}
                             type="submit"
                             onClick={this.contactSubmit}
                             disabled={loading}
                           >
                             {loading && (
                               <i
                                 className="fa fa-refresh fa-spin"
                                 style={{ marginRight: "5px" }}
                               />
                             )}
                             {loading && <span>Loading...</span>}
                             {!loading && <span>Sign Up</span>}
                           </button>

				               </div>
				            </div>
				         </div>

				      </div>
				   </div>
				</div>
<Footer />
			</div>
);
}
}
export default Signup;
