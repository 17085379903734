import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import $ from 'jquery';
import '../../assets/css/bootstrap.css';
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/font-awesome.min.css';
import blackForma from '../../assets/images/black-Forma-1.png';
import yellowArrows from '../../assets/images/yellow-arrows.png';
import shape1 from '../../assets/images/Shape-1.png';
import {NavLink} from 'react-router-dom';
import {api_url} from '../../Config.js';
import '../../assets/css/style.css';
class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			base_url:api_url.base_url,
      		img_url:api_url.img_url,
      		com_url:api_url.com_url,
			data:[],
			redirectToReferrer: false,
			filterValue:'all',
			dup:'',
			redirect: false,
			logo:'',
			favicon:'',
			chat_script:'',
		};
		this.logout = this.logout.bind(this);
		// this.routeChange = this.routeChange.bind(this);
		// this.searchItem = this.searchItem.bind(this);
	}
    componentDidMount(){

    		$(".all_category h3 svg").click(function(){
			    $(".sidebar_category").toggle();
			});

			$(".menu_btn").click(function(){
			    $(".menu_bar").show();
			});
			$(".close_btn").click(function(){
			    $(".menu_bar").hide();
			});


			$(".read_more_btn").click(function(){
			    $(".hide_box").show();
			});

			$(".read_more_btn").click(function(){
			    $(".read_more_btn").hide();
			});

			$(".collapsed").click(function(){
			    $(".collapse_info").slideToggle();
			});

			// $(".all_category h3").click(function(){
			// 	$(".sidebar_category").toggle();
			// });

		const home_setting = this.state.com_url+'home_api/gethomesetting';
	    fetch(home_setting)
	      .then(res => res.json())
	      .then(
	        (result)=>{
	          this.setState({
	            logo:result.data.detail.logo,
				favicon:result.data.detail.favicon,
				chat_script:result.data.detail.live_chat_script,
	          });
	        },
	        (error) => {
	          this.setState({error});
	        }
	      )
	}

  	handleChange = (e) => {
	   this.setState({ filterValue: e.target.value });
	}
	searchItem =(e) =>{
	    var filterValue = this.state.filterValue;
		this.setState({
			filterValue:filterValue,
			dup:filterValue,
			redirect: true
		});
	}
	keyPress=(e)=>{
      if(e.keyCode == 13){
        var filterValue = this.state.filterValue;
		this.setState({
			filterValue:filterValue,
			dup:filterValue,
			redirect: true
		});
      }
   }
   	renderRedirect = () => {
	    if (this.state.redirect) {
	     window.location.href = '#/search-results/all/'+this.state.filterValue+'/all';
	     window.location.reload();
	      // return <Redirect to={`/search-results/${this.state.filterValue}`} />
	    }
  	}
	logout(){
		localStorage.removeItem("userData");
		localStorage.clear();
		localStorage.clear();
		localStorage.removeItem('checkoutVali');
		this.setState({redirectToReferrer: true});
	}
render() {

	if (this.state.redirectToReferrer) {
		return (<Redirect to={'/'}/>)
	}
return (
<div>
	{this.renderRedirect()}
	<div>
		{this.state.chat_script}
		<div className="top_menu_Bar">
			<div className = "container" >
				<div className = "row" >
					<div className = "col-sm-6 online_shipping" >
						<ul>
							<li>
								<span>Email: support@mios.pk</span>
							</li>
						</ul>
					</div>
					<div className = "col-sm-6 online_shipping" >
						<ul>
							{(localStorage.getItem('userData') ?
								"":
								<li>
									<NavLink to="/signup">Register</NavLink>
								</li>
							)}
							{(localStorage.getItem('userData') ?
								<li>
									<NavLink to="/profile">Profile</NavLink>
								</li>
								:
								<li>
									<NavLink to="/login">Login</NavLink>
								</li>
							)}
							{(localStorage.getItem('userData') ?
								<li>
									<NavLink to="/check-out">Checkout</NavLink>
								</li>
								:""
							)}
							{(localStorage.getItem('userData') ?
								<li>
									<NavLink onClick={this.logout} to="/" >Logout</NavLink>
								</li>
								:""
							)}
							<li><NavLink to="/contact-us">Contact Us</NavLink></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div className="header_wrap" id="header_wrap">
		<div className="container">
			<div className="row">
				<div className="menu_btn">
					<i className="fa fa-bars"></i>
				</div>
				<div className="col-sm-3 logo_img">
					<NavLink to="/">
					<img src={this.state.img_url+`/${this.state.logo}`} alt="img" /></NavLink>
				</div>
				<div className="col-sm-7 all_category">
					<div className="row">
						<div className="col-sm-3 category_dropdown">
							<select>
								<option>All Categories</option>
								<option>All Categories 1</option>
								<option>All Categories 2</option>
							</select>
						</div>
						<div className="col-sm-9 search_box">
							<input type="text" value={this.state.filterValue !== 'all' ? this.state.filterValue :''} placeholder="Search Products..." onKeyDown={e=>this.keyPress(e)} onChange={e =>this.handleChange(e)} id="search-items" />
								<NavLink to={`/search-results/all/${this.state.filterValue}/all`}  onClick={e=>this.searchItem(e)}><button type="button"><img src={blackForma} alt="" /> Search</button></NavLink>

							<div className="angle_search">
							<img src={yellowArrows} alt="yellow-arrows" />
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-2 user_cart">
					<ul>
						{(
							localStorage.getItem('userData') &&
							<li className="hover_items heart_box">
								<NavLink to="/my-favourite"><i className="fa fa-heart-o heart"></i></NavLink>
							</li>
						)}
						<li>
							<NavLink to="/cart"><img src={shape1} alt="" />
							<p>{ (localStorage.getItem('cartCount') > 0) ? localStorage.getItem('cartCount') : 0   }</p></NavLink>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
	<section className="category_header ">
		<div className="container">
			<div className="row">
				<div className="col-lg-3 col-md-3 col-sm-3 all_category">
					<h3>
						ALL CATEGORIES
						{(
							(window.location.pathname === '/') &&
							<svg  width="1.15em" height="1em"  preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 896"><path d="M27 130q-4-4-7-9t-4-10.5T15 98q0-19 12-31t31-12h913q19 0 31 12t12 31t-12 31.5t-31 12.5H58q-19 0-31-12zm975 285q12 12 12 31t-12 31t-31 12H58q-19 0-31-12q-2-2-4-5t-3.5-6t-2.5-6t-1.5-6.5t-.5-7.5q0-19 12-31t31-12h913q19 0 31 12zm0 347q12 12 12 31t-12 31.5t-31 12.5H58q-19 0-31-12q-2-2-3.5-4.5l-3-5l-2.5-5l-2-5.5l-1-5.5V793q0-19 12-31t31-12h913q18 0 31 12z" fill="#fff"/></svg>

						)}
					</h3>
				</div>
				<div className="col-lg-9 col-md-9 col-sm-12 menu_bar">
					<nav className="navbar_items">
						<div className="close_btn">
							<i className="fa fa-close"></i>
						</div>
						<ul className="nav navbar-nav-items">
							<li><NavLink to="/">Home</NavLink></li>
							{(localStorage.getItem('userData') ?
								<li><NavLink to="/order">Orders</NavLink></li>
								:""
							)}
							<li><NavLink to="/about-us">About Us</NavLink></li>
							<li><NavLink to="/contact-us">Contact Us</NavLink></li>
						</ul>
					</nav>
				</div>
			</div>
		</div>
	</section>
</div>
);
}
}
export default Home;
