import React, { Component } from 'react';
import loading from '../assets/images/loading.gif';
import {NavLink} from 'react-router-dom';
import Header  from "./Header/Header";
import Footer  from "./Footer/Footer";
import {api_url}  from "../Config.js";
import {Unfavourite} from '../services/Unfavourite.js';
import UserSidebar from './UserSidebar';
import DComponent from './Home/DynamicComponent';
import LComponent from './Home/LoadComponent';
class MyFavourite extends Component {
constructor(props) {
super(props);
this.state = {
data:[],
redirectToReferrer: false,
feedback:[],
base_url:api_url.base_url,
img_url:api_url.img_url,
com_url:api_url.com_url,
itemShow:2,
prefix:'',
suffix:'',
loading:false,
};
this.Unfavourite = this.Unfavourite.bind(this);
this.setStateOfParent = this.setStateOfParent.bind(this);
}
async setStateOfParent (result) {

    // this.props.setStateOfParent(result);
    await this.setState({
      cartCount:result
    },()=>{
      localStorage.setItem('cartCount',result);
      localStorage.setItem('cartCount',result);
    })
  }
componentDidMount()
  {
    // console.log(localStorage.getItem('userData'))
    let filterid = localStorage.getItem('userData')
    const filteredUrl = this.state.com_url+'moiz_api/getFavoriteProductsById';
    this.setState({loading:true,})
    fetch(filteredUrl+'?id='+filterid)
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
          this.setState({
            loading:false,
            feedback:result.favproducts,

          },function(){
        console.log(this.state.feedback)
          });
        },
        (error) => {
          this.setState({ error });
        }

      )
      const prefixUrl = this.state.base_url+'products_api/GetPrefix';

    fetch(prefixUrl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            prefix: result

          }, function(){

          });

        },
        (error) => {
          this.setState({ error });
        }
      )
const suffixUrl = this.state.base_url+'products_api/GetSuffix';

    fetch(suffixUrl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            suffix: result

          }, function(){

          });
        },
        (error) => {
          this.setState({ error });
        }
      )

  }
componentWillMount() {
if(localStorage.getItem("userData")){
this.getUserData();
}
else{
this.setState({redirectToReferrer: true});
}
}
getUserData() {
JSON.parse(localStorage.getItem("userData"));

}
Unfavourite(e,value){
  value.user_id=1;
    Unfavourite(value);
}

loadmore(){
  // this.setState({
  //   itemShow:this.state.itemShow+ parseFloat(2)
  // })
  this.setState((old)=>{
    return {itemShow:old.itemShow + parseFloat(2)}
  },function(){
    console.log(this.state.itemShow)
  })
}

render() {
const {feedback} = this.state
		return (
			<div>
        <Header />
         <div className="user_profile_wrap">
            <div className="container" id="search_result-page">
               <div className="row">
                  <div className="col-sm-2 user_profile_left">
                     <h3>Orders</h3>
                     <UserSidebar active="favourite" />
                  </div>
                  <div className="col-sm-10 accont_right_layout">
                     <h3>My Favourite</h3>

                     <div className="tabs_wrap table-responsive">
                        <div className="row">
                           <div className="col-lg-12 tab-section-" style={{paddingTop: '0px'}}>

                              <div className="main_tab">
                                 <div className="table-responsive tabs_wrap" id="home" style={{display: 'block'}}>
                                 <table id="fav_tbl" className="customers--" >
                                        <thead>
                                        </thead>
                                        <tbody>

                                    {
                                      (this.state.loading)?

                                      <div style={{textAlign:'center'}}>
                                      <img src={loading} alt="loader" /></div>

                                      :

                                      (feedback.length>0)?

                                      feedback.slice(0,this.state.itemShow).map((feed,index)=>(


                                        <DComponent setStateOfParent={this.setStateOfParent} title={feed} key={index} user_id={sessionStorage.getItem('userData')} value={index}  />


                                      ))
                                      :

                                      <h4>No Record Found</h4>

                                    }
                                     </tbody>
                                      </table>
                                 </div>

                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="form-wrapper-trad">
                        <div className="row">
                           <div className="col-lg-12 padding-set-left">
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <Footer />
      </div>
		);
	}
}

export default MyFavourite;
