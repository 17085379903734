import React, { Component } from 'react';
import loading from '../assets/images/loading.gif';
import {NavLink} from 'react-router-dom';
import Header  from "./Header/Header";
import Footer  from "./Footer/Footer";
import {Redirect} from 'react-router-dom';
import {api_url} from '../Config.js';
import axios from 'axios'
import ReactPaginate from 'react-paginate';
import UserSidebar from './UserSidebar';
class Order extends Component {
	constructor(props) {
		super(props);
		this.state = {
			allorder:[],
			offset: 0,
	        data: [],
	        perPage: 20,
	        currentPage: 0,
			redirectToReferrer: false,
			base_url:api_url.base_url,
	  		img_url:api_url.img_url,
	  		com_url:api_url.com_url,
	  		current_user:localStorage.getItem('userData'),
	  		loading:false,
	  		slice:[],
	  		success_message:(localStorage.getItem('success')) ? localStorage.getItem('success'):'',
	  		type:'all',
		};
		this.handlePageClick = this
            .handlePageClick
            .bind(this);
		this.getAllOrders = this.getAllOrders.bind(this);
		this.changeType = this.changeType.bind(this);
		this.refresh = this.refresh.bind(this);
	}
	componentDidMount(){
		this.receivedData('all');
		if(localStorage.getItem('success'))
		{
			localStorage.removeItem('success');
		}
		this.getAllOrders();
	}

	componentWillMount() {
	if(localStorage.getItem("userData")){
			this.getUserData(this.state.type);
		}
		else
		{
			this.setState({redirectToReferrer: true});
		}
	}
	changeType(type){ 
		this.setState({type:type},()=>{

			this.receivedData(this.state.type);
		});
	}
	getUserData() {
		JSON.parse(localStorage.getItem("userData"));
	}
 getAllOrders(type=null){
	// const caturl = this.state.com_url+'moiz_api/getAllOrders/'+this.state.current_user+"/"+type;
	// this.setState({loading:true})
 //    await fetch(caturl)
 //      .then(res => res.json())
 //      .then(
 //        (result) => {
 //          this.setState({
 //          	allorder:result.orders,
 //          	loading:false,
 //          }); 
 //        },
 //        (error) => {
 //          this.setState({ error });
 //        }
 //      )
}
refresh(){ 
	window.location.reload(false);
}
async receivedData(type=null) {
    await axios
        .get(this.state.com_url+'moiz_api/getAllOrders/'+this.state.current_user+"/"+type)

        .then(res => {


            const data = res.data.orders;
            const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)


            this.setState({
                pageCount: Math.ceil(data.length / this.state.perPage),
                slice:slice,
            })
        });
}
handlePageClick (e) {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
        currentPage: selectedPage,
        offset: offset
    }, () => {
        this.receivedData(this.state.type)
    });

};


render() {

	return (
			<div>
			<Header />
			   <div className="user_profile_wrap">
			      <div className="container">
			      	<div className="row">
			      		{(
			      			this.state.success_message!='' ?
				      		<div className="col-sm-12">
				      			<div className="alert alert-success">
				      				{this.state.success_message}
				      			</div>
				      		</div>
				      		:""
			      		)}
			      	</div>
			         <div className="row">
			            <div className="col-sm-2 user_profile_left">
			               <h3 >Orders</h3>
			               <UserSidebar active="all_order" />
			            </div>
			            <div className="col-sm-10 accont_right_layout">
			            	<div className="row">
			            		<div className="col-sm-2 sidegapp order_dropbox">
					               <h3 onClick={this.refresh} style={{'cursor':'pointer'}}>Orders</h3>
			            		</div>
			            		<div className="col-sm-10 sidegapp dropship_box">
					               <button type="button" onClick={()=>this.changeType('dropship')}>Dropship</button>
					               <button type="button" className="wholesale" onClick={()=>this.changeType('wholesale')}>Wholesale</button>
			            		</div>
			            	
			            	</div>


			               <div className="form-wrapper-set">
			                  <div className="row">
			                     <div className="col-lg-4 form-input-d">
			                        <div className="form-ch-data">
			                           <label>Order number  </label>
			                           <input type="text" />
			                        </div>
			                     </div>
			                     <div className="col-lg-4 form-input-d">
			                        <div className="form-ch-data">
			                           <label>Product</label>
			                           <input type="text" />
			                        </div>
			                     </div>
			                     <div className="col-lg-4 form-input-d ">
			                        <div className="form-ch-data">
			                           <label>Seller Name  </label>
			                           <input type="text" />
			                        </div>
			                     </div>
			                     <div className="col-lg-4 form-input-d padding-top-bottom">
			                        <div className="form-ch-data">
			                           <label>Tracking number </label>
			                           <input type="text" />
			                        </div>
			                     </div>
			                     <div className="col-lg-4 form-input-d padding-top-bottom">
			                        <div className="form-ch-data">
			                           <label>Status  </label>
			                           <select name="" id="">
			                              <option >All Orders</option>
			                              <option > Awaiting payment </option>
			                              <option > Awaiting Cancellation </option>
			                              <option > Awaiting Shipment </option>
			                              <option > Partial Shipment </option>
			                              <option > Awaiting delivery </option>
			                              <option > Grouping </option>
			                              <option > Order Completed </option>
			                              <option > Frozen Orders </option>
			                              <option > Payment not yet confirmed </option>
			                              <option > Payment being verified </option>
			                           </select>
			                        </div>
			                     </div>
			                     <div className="col-lg-4 form-input-d padding-top-bottom">
			                        <div className="form-ch-data">
			                           <label>Date range  </label>
			                           <input type="date" placeholder="MM/DD/YYYY" />
			                        </div>
			                     </div>
			                     <div className="col-lg-4 form-input-d">
			                        <button>Search</button>
			                     </div>
			                  </div>
			               </div>
			               <div className="form-wrapper-trad">
			                  <div className="row">
			                     <div className="col-lg-12 padding-set-left">


			                        	{(this.state.loading) ?
						                  <div style={{textAlign:'center'}}>
										  <img src={loading} /></div>
										:
						                  <div className="table-responsive">
						                  <table id="customers">

				                        <thead>
				                           <tr>
				                              <th rowSpan="1" colSpan="1">Order ID</th>
				                              <th rowSpan="1" colSpan="1">Order Time</th>
				                              <th rowSpan="1" colSpan="1">Total Amount</th>
				                              <th rowSpan="1" colSpan="1">Order Status</th>
				                              <th rowSpan="1" colSpan="1">Action</th>
				                           </tr>
				                        </thead>
				                        <tbody>
				                        	{this.state.slice.map(item =>
							                    <tr className="fixed-table" key={item.id}>
							                  <td colSpan="1">
							                        <p>{item.id}</p>
							                  </td>
							                  <td colSpan="1">
							                       <p>{item.created_on} </p>
							                  </td>
							                  <td colSpan="1" className="red-trash">
							                  	<p>{item.price_currency}  {item.price_total}</p>
							                  </td>
							                  <td colSpan="1" className="red-trash">
							                  	<p>{item.status_name}</p>
							                  </td>
							                  <td colSpan="1" className="red-trash">
							                  	<p>
							                  		<NavLink to={`/order-detail/${item.id}`}>  <i className="fa fa-eye" ></i>  View Detail</NavLink>

							                  	</p>
							                  </td>
							               </tr>
							               )}

				                         </tbody>

			                        </table>
				                         <ReactPaginate
						                    previousLabel={"Prev"}
						                    nextLabel={"Next"}
						                    breakLabel={"..."}
						                    breakClassName={"break-me"}
						                    pageCount={this.state.pageCount}
						                    marginPagesDisplayed={1}
						                    pageRangeDisplayed={2}
						                    onPageChange={this.handlePageClick}
						                    containerClassName={"pagination"}
						                    subContainerClassName={"pages pagination"}
						                    activeClassName={"active"}/>
						               </div>
			                        }
			                     </div>
			                  </div>
			               </div>
			            </div>
			         </div>
			      </div>
			   </div>
			<Footer />
		</div>
		);
	}
}

export default Order;
